const ownerStatusTransitions = [
  {
    current: 'Draft',
    updated: 'Submitted',
  },
  {
    current: 'Submitted',
    updated: 'Draft',
  },
  {
    current: 'Declined',
    updated: 'Submitted',
  },
];

const PMOrDMStatusTransitions = [
  {
    current: 'Draft',
    updated: 'Approved',
  },
  {
    current: 'Draft',
    updated: 'Declined',
  },
  {
    current: 'Submitted',
    updated: 'Approved',
  },
  {
    current: 'Submitted',
    updated: 'Declined',
  },
  {
    current: 'Declined',
    updated: 'Approved',
  },
  {
    current: 'Approved',
    updated: 'Declined',
  },
];

export { ownerStatusTransitions, PMOrDMStatusTransitions };
