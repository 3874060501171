import { mapState, mapGetters, mapActions } from 'vuex';

import { pick } from '@/helpers/pick';

/**
 * @template T
 * @typedef {import('@/store/types/mapped-state').MappedState<T>} MappedState<T>
 */
/**
 * @template T
 * @typedef {import('@/store/types/mapped-getters').MappedGetters<T>} MappedGetters<T>
 */
/**
 * @typedef {import('./shared-roles.actions').SharedRolesActions} SharedRolesActions
 * @typedef {import('./shared-roles.getters').SharedRolesGetters} SharedRolesGetters
 * @typedef {import('./shared-roles.state').SharedRolesState} SharedRolesState
 */

/** @type {SharedRolesActions} */
const mappedSharedRolesActions = {
  ...mapActions('shared/roles', [
    'getProjectRoles',
    'getSystemRoles',
    'getSystemRolesICanSet',
  ]),
};

/**
 * @template {keyof typeof mappedSharedRolesActions} A
 * @param {A[]} [actions]
 * @returns {Pick<typeof mappedSharedRolesActions, A[][number]>}
 */
export const mapSharedRolesActions = (actions) => pick(mappedSharedRolesActions, actions);

/** @type {MappedState<SharedRolesState> & MappedGetters<SharedRolesGetters>} */
const mappedSharedRolesState = {
  ...mapState('shared/roles', [
    'projectRoles',
    'systemRoles',
    'systemRolesICanSet',
  ]),
  ...mapGetters('shared/roles', ['projectRolesMap']),
};

/**
 * @template {keyof typeof mappedSharedRolesState} S
 * @param {S[]} [states]
 * @returns {Pick<typeof mappedSharedRolesState, S[][number]>}
 */
export const mapSharedRolesState = (states) => pick(mappedSharedRolesState, states);
