<template>
  <Dialog
    title="Import from Jira"
    confirmation-button-text="Import"
    close-button-text="Close"
    :is-confirmation-button-disabled="!valid"
    :dialog="opened"
    :loading="isImporting"
    @confirmationAction="handleConfirmation"
  >
    <template slot="content">
      <v-form
        ref="form"
        v-model="valid"
        class="jira-import-form"
      >
        <v-row>
          <v-select
            ref="connection"
            v-model="connectionId"
            class="secondary-background"
            label="Connection"
            item-text="connection_name"
            item-value="id"
            hide-details
            outlined
            dense
            :loading="isFetchingConnections"
            :items="jiraCredentials"
            :rules="[(v) => !!v || 'Connection is required']"
            @change="fetchJiraProjects"
          />
        </v-row>

        <v-row>
          <v-combobox
            ref="jiraProjectInput"
            v-model="jiraProject"
            class="secondary-background"
            item-text="name"
            label="JIRA project"
            persistent-hint
            hide-details
            outlined
            dense
            :disabled="!connectionId || isFetchingProjects"
            :loading="isFetchingProjects"
            :menu-props="{ offsetY: true }"
            :items="jiraProjects"
            :rules="[(v) => !!v || 'Jira project is required']"
          />
        </v-row>
        <v-row>
          <v-combobox
            ref="etsProjectInput"
            v-model="etsProject"
            class="secondary-background"
            item-text="title"
            label="ETS project"
            persistent-hint
            hide-details
            outlined
            dense
            :menu-props="{ offsetY: true }"
            :items="userProjectsWithPrevOrCurrentProject"
            :rules="[(v) => !!v || 'ETS project is required']"
          />
        </v-row>

        <v-row>
          <v-col class="pr-2">
            <DatePicker
              ref="startDate"
              label="Start date"
              :value="startDate"
              :rules="[(v) => !!v || 'Start date is required']"
              @input="changeStartDate"
            />
          </v-col>
          <v-col>
            <DatePicker
              ref="endDate"
              v-model="endDate"
              label="End date"
              :min-date="startDate"
              :rules="[(v) => !!v || 'End date is required']"
            />
          </v-col>
        </v-row>
      </v-form>
    </template>
  </Dialog>
</template>

<script>
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';

import { Dialog, DatePicker } from '@/components/shared';
import assignmentStatuses from '@/constants/assignmentStatuses';
import { projectRolesNames } from '@/constants/roles';
import { CollectionsHelper } from '@/helpers/collections.helper';
import { DateHelper } from '@/helpers/date.helper';
import { mapAuthAccountState } from '@/store/auth/account/auth-account.mappers';
import { mapSharedRolesState } from '@/store/shared/roles/shared-roles.mappers';
import { mapUserJiraActions, mapUserJiraState } from '@/store/user/jira/user-jira.mappers';

/**
 * @typedef {import('@/models/project.interface').Project} Project
 * @typedef {import('@/services/api-client/types/jira.api.types').JiraImportTimeUnitsParams} JiraImportTimeUnitsParams
 * @typedef {import('@/services/api-client/types/jira.api.types').JiraProject} JiraProject
 */

export default {
  name: 'ImportFromJiraDialog',
  components: {
    Dialog,
    DatePicker,
  },
  props: {
    opened: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      valid: false,
      connectionId: '',
      startDateInput: '',
      endDateInput: '',
      /** @type {JiraProject | null} */
      jiraProject: null,
      /** @type {Project | null} */
      etsProject: null,
      isImporting: false,
      isFetchingProjects: false,
      isFetchingConnections: false,
    };
  },
  computed: {
    ...mapUserJiraState(['jiraCredentials', 'jiraProjects']),
    ...mapAuthAccountState(['userProjects']),
    ...mapSharedRolesState(['projectRolesMap']),
    ...mapGetters('timesheet/filters', [
      'dateRangeFilter',
    ]),

    startDate: {
      /** @returns {string} */
      get() {
        return this.startDateInput || this.dateRangeFilter.startDate;
      },
      /** @param {string} value */
      set(value) {
        this.startDateInput = value;
      },
    },

    endDate: {
      /** @returns {string} */
      get() {
        return this.endDateInput || this.dateRangeFilter.endDate;
      },
      /** @param {string} value */
      set(value) {
        this.endDateInput = value;
      },
    },
    /**
     * TODO: extract into helper function or vuex getter, same function is already used in time unit input
     * @returns {({ divider?: true, header?: string } | Project)[]}
     */
    userProjectsWithPrevOrCurrentProject() {
      const teamMemberRole = this.projectRolesMap[projectRolesNames.teamMember];

      if (!teamMemberRole) return [];

      /** @type {Project[]} */
      const activeStatusProjects = this.userProjects.filter(
        (project) => project.assignments.some(
          (assignment) => {
            const isTeamMember = assignment.role_id === teamMemberRole.id;
            const isProspective = assignment.status === assignmentStatuses.prospective;
            const isActive = assignment.status === assignmentStatuses.active;

            return isTeamMember && (isProspective || isActive);
          }
        ),
      );
      /** @type {Project[]} */
      const removedStatusProjects = this.userProjects.filter(
        (project) => {
          const hasRemovedAssignment = project.assignments.some((assignment) => {
            const isTeamMember = assignment.role_id === teamMemberRole.id;
            const isRemoved = assignment.status === assignmentStatuses.removed;

            return isTeamMember && isRemoved;
          });
          const hasNoActiveAssignments = project.assignments.every((assignment) => {
            const isTeamMember = assignment.role_id === teamMemberRole.id;
            const isActive = assignment.status === assignmentStatuses.active;

            return !(isTeamMember && isActive);
          });

          return hasRemovedAssignment && hasNoActiveAssignments;
        },
      );

      /** @type {Project[]} */
      const sortedActiveProjects = CollectionsHelper.sortObjectsByProperty(activeStatusProjects, 'title');
      /** @type {Project[]} */
      const sortedRemovedProjects = CollectionsHelper.sortObjectsByProperty(removedStatusProjects, 'title');

      if (removedStatusProjects.length) {
        return [
          ...sortedActiveProjects,
          { divider: true },
          { header: 'Former Projects' },
          ...sortedRemovedProjects,
        ];
      }

      return sortedActiveProjects;
    },
  },
  watch: {
    connectionId() {
      this.jiraProject = null;
    },
  },
  async created() {
    this.isFetchingConnections = true;

    try {
      await this.getJiraCredentials();
    } finally {
      this.isFetchingConnections = false;
    }
  },
  methods: {
    ...mapUserJiraActions([
      'getJiraCredentials',
      'getJiraProjects',
      'importTimeUnits',
    ]),
    ...mapActions('timesheet/timeUnits', [
      'updateStoredTimeUnits',
    ]),
    /** @param {string} jiraCredentialsId */
    async fetchJiraProjects(jiraCredentialsId) {
      this.isFetchingProjects = true;

      try {
        await this.getJiraProjects({ jiraCredentialsId });
      } finally {
        this.isFetchingProjects = false;
      }
    },

    /** @param {string} value */
    changeStartDate(value) {
      this.startDate = value;

      if (this.startDate !== this.endDate && !DateHelper.isDayBefore(this.startDate, this.endDate)) {
        const newEndDate = DateHelper.toIso(moment(this.startDate).endOf('month'));

        this.endDate = newEndDate;
      }
    },

    /** @param {boolean} confirmed */
    handleConfirmation(confirmed) {
      if (!confirmed) {
        this.$emit('close');
        this.startDateInput = '';
        this.endDateInput = '';

        return;
      }

      if (!this.jiraProject) throw new Error('Jira project is not chosen');

      if (!this.etsProject) throw new Error('ETS project is not chosen');

      /** @type {JiraImportTimeUnitsParams} */
      const options = {
        jiraCredentialsId: this.connectionId,
        jiraProjectKey: this.jiraProject.key,
        etsProjectId: this.etsProject.id,
        startDate: this.startDate,
        endDate: this.endDate,
      };

      this.importFromJira(options);
    },

    /** @param {JiraImportTimeUnitsParams} importOptions */
    async importFromJira(importOptions) {
      this.isImporting = true;

      try {
        await this.importTimeUnits(importOptions);

        this.updateStoredTimeUnits({ reset: true });
        this.$emit('close');
      } finally {
        this.isImporting = false;
      }
    },
  },
};
</script>

<style lang="less">
.jira-import-form .row:not(:last-child) {
  margin-bottom: 16px !important;
}
</style>
