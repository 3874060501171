<template>
  <div>
    <v-row class="pa-4 bar-shadow primary-background">
      <v-col>
        <TimesheetFilters :is-status-customizing="isStatusSwitchHovered" />
      </v-col>
      <div
        :class="{
          'timesheet-buttons': true,
          'timesheet-buttons--small': isLgAndDownScreen,
          'timesheet-buttons--fix-height': isMdAndDownScreen,
        }"
      >
        <v-row
          class="fill-height"
          align="center"
          justify="end"
        >
          <div class="analitic-btn">
            <v-tooltip bottom>
              <template #activator="{ on: tooltip }">
                <a href="/dashboard?targetName='Personal'">
                  <v-btn
                    class="mr-4"
                    icon
                    v-on="{ ...menu, ...tooltip }"
                  >
                    <AnalyticIcon>Some text</AnalyticIcon>
                  </v-btn>
                </a>
              </template>
              <span>{{ BItext }}</span>
            </v-tooltip>
          </div>
          <v-tooltip bottom>
            <template #activator="{ on }">
              <v-icon
                class="mr-2"
                v-on="on"
              >
                help_outline
              </v-icon>
            </template>
            <UnitShortcuts :unit-subject="'time unit'" />
          </v-tooltip>
          <v-menu
            v-model="customizeTablePopover"
            offset-y
            left
            :close-on-content-click="false"
            content-class="customize-table-popover v-dialog"
          >
            <template #activator="{ on: menu }">
              <v-tooltip bottom>
                <template #activator="{ on: tooltip }">
                  <v-btn
                    class="mr-4"
                    icon
                    v-on="{ ...menu, ...tooltip }"
                  >
                    <v-icon>mdi-settings-outline</v-icon>
                  </v-btn>
                </template>
                <span>{{ customizeTableText }}</span>
              </v-tooltip>
            </template>
            <v-card>
              <v-card-title
                class="text-h5 lighten-2"
                primary-title
                v-text="customizeTableText"
              />
              <v-card-text class="pb-4 pt-2">
                <v-switch
                  color="primary"
                  label="Show &quot;Overtime&quot; column"
                  hide-details
                  :input-value="isColumnShown('overtime')"
                  @change="changeHiddenColumns('overtime')"
                />
                <template v-if="isSystemRoleForProject">
                  <v-switch
                    color="primary"
                    label="Show &quot;Overtime Payable&quot; column"
                    hide-details
                    :input-value="isColumnShown('is_overtime_payable')"
                    @change="changeHiddenColumns('is_overtime_payable')"
                  />
                  <v-switch
                    color="primary"
                    label="Show &quot;Overtime Multiplier&quot; column"
                    hide-details
                    :input-value="isColumnShown('overtime_multiplier')"
                    @change="changeHiddenColumns('overtime_multiplier')"
                  />
                  <v-switch
                    color="primary"
                    label="Show &quot;Billable&quot; column"
                    hide-details
                    :input-value="isColumnShown('is_billable')"
                    @change="changeHiddenColumns('is_billable')"
                  />
                </template>
                <v-hover v-model="isStatusSwitchHovered">
                  <v-switch
                    color="primary"
                    label="Show &quot;Status&quot; column"
                    hide-details
                    :input-value="isColumnShown('status')"
                    @change="changeStatusColumnVisibility"
                  />
                </v-hover>
              </v-card-text>
            </v-card>
          </v-menu>
          <v-tooltip bottom>
            <template #activator="{ on }">
              <Button
                id="add-time-unit"
                class="mr-3"
                color="primary"
                :disabled="!isUserTimeUnits || isTimesheetEditing || isDateRangeOpen || isCurrentTimeUnitsLoading"
                :on="!largeScreenHelper ? on : null"
                @clickAction="addNewTimeUnit"
              >
                <v-icon v-if="!largeScreenHelper">add</v-icon>
                <span v-else>{{ addTimeUnitText }}</span>
              </Button>
            </template>
            <span>{{ addTimeUnitText }}</span>
          </v-tooltip>

          <template>
            <div class="text-center">
              <v-menu
                transition="scale-transition"
                :offset-y="true"
              >
                <template #activator="{ on: menu }">
                  <v-tooltip
                    bottom
                    :disabled="largeScreenHelper"
                  >
                    <template #activator="{ on: tooltip }">
                      <Button
                        id="excel-tools"
                        class="turnOrange excel-tools-button"
                        :action-names="[loadingTypes.timesheetExcelTools]"
                        :on="{ ...tooltip, ...menu }"
                      >
                        <v-icon v-if="!largeScreenHelper">mdi-import</v-icon>
                        <span v-else>{{ importToolsText }}</span>
                      </Button>
                    </template>
                    <span>{{ importToolsTooltip }}</span>
                  </v-tooltip>
                </template>
                <v-list>
                  <v-list-item
                    v-for="item in importActionItems"
                    :key="item.key"
                    @click="onXlsItemChoosen(item.key)"
                  >
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </template>
          <input
            ref="importFile"
            type="file"
            style="display: none"
            @change="importFromExcel"
          >
        </v-row>
      </div>
    </v-row>

    <v-row
      v-if="projectRoles.length"
      class="pa-4"
    >
      <TimesheetTable
        :items="filteredTimeUnits"
        :is-status-customizing="isStatusSwitchHovered"
      />
    </v-row>

    <ExportToExcelDialog
      :opened="isExcelExportDialogOpen"
      @close="isExcelExportDialogOpen = false"
      @addRaftLogo="addRaftLogoHandler"
    />

    <ImportFromJiraDialog
      :opened="isJiraDialogOpened"
      @close="isJiraDialogOpened = false"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { mapCacheActions } from 'vuex-cache';

import AnalyticIcon from '@/assets/BI1.svg';
import { Button, UnitShortcuts } from '@/components/shared';
import { loadingTypes } from '@/constants/loadingTypes';
import { isLargeScreen } from '@/helpers/screen.helper';
import apiClient from '@/services/api-client';

import ExportToExcelDialog from '../shared/ExportToExcelDialog.vue';

import ImportFromJiraDialog from './ImportFromJiraDialog.vue';
import TimesheetFilters from './TimesheetFilters.vue';
import TimesheetTable from './TimesheetTable.vue';

/** @enum {string} */
const MenuItem = {
  EXCEL_EXPORT: 'EXCEL_EXPORT',
  EXCEL_IMPORT: 'EXCEL_IMPORT',
  JIRA_IMPORT: 'JIRA_IMPORT',
  TEMPLATE_DOWNLOAD: 'TEMPLATE_DOWNLOAD',
};

export default {
  name: 'TimesheetPage',
  components: {
    Button,
    TimesheetFilters,
    TimesheetTable,
    UnitShortcuts,
    ExportToExcelDialog,
    ImportFromJiraDialog,
    AnalyticIcon,
  },

  data() {
    return {
      addTimeUnitText: 'Add Time Unit',
      customizeTableText: 'Customize table\'s columns',
      importToolsText: 'Import tools',
      importToolsTooltip: 'Import, export or download import template',
      loadingTypes,
      customizeTablePopover: false,
      importActionItems: [
        { title: 'Export to Excel', key: MenuItem.EXCEL_EXPORT },
        { title: 'Import from Excel', key: MenuItem.EXCEL_IMPORT },
        { title: 'Import from Jira', key: MenuItem.JIRA_IMPORT },
        { title: 'Download import template', key: MenuItem.TEMPLATE_DOWNLOAD },
      ],
      isStatusSwitchHovered: false,
      isJiraDialogOpened: false,
      isExcelExportDialogOpen: false,
      addRaftLogoValue: true,
      excelOperationInProgress: false,
      BItext: 'Personal Analytics',
    };
  },

  computed: {
    ...mapGetters('timesheet/filters', [
      'employeeFilter',
      'projectFilter',
      'dateRangeFilter',
      'statusFilter',
    ]),
    ...mapGetters('timesheet/table', ['isColumnShown']),
    ...mapGetters('timesheet/timeUnits', [
      'isCurrentTimeUnitsLoading',
      'filteredTimeUnits',
      'isTimesheetEditing',
    ]),
    ...mapGetters('auth/account', ['user', 'isUserEmployee', 'userProjects']),
    ...mapGetters('employees/single', ['isSystemRoleForProject']),
    ...mapGetters('shared/common', ['isDateRangeOpen']),
    ...mapGetters('projects/main', ['projects']),
    ...mapGetters('shared/roles', ['projectRoles']),

    /** @returns {boolean} */
    isLgAndDownScreen() {
      return this.$vuetify.breakpoint.lgAndDown;
    },
    /** @returns {boolean} */
    isMdAndDownScreen() {
      return this.$vuetify.breakpoint.mdAndDown;
    },
    /** @returns {boolean} */
    isUserTimeUnits() {
      return (
        this.employeeFilter.id === null
        || this.user.id === this.employeeFilter.id
      );
    },
    largeScreenHelper() {
      return isLargeScreen(this.$vuetify.breakpoint, true);
    },
  },

  created() {
    const isNeededToLoadProjects = !this.isUserEmployee && this.projects.length === 0;

    Promise.all([
      isNeededToLoadProjects ? this.getProjects(this.user.id) : Promise.resolve(),
      this.fetchUserProjects(),
      this.getProjectRoles(),
    ]);

    document.addEventListener('keyup', this.handleKeyup);
  },

  destroyed() {
    document.removeEventListener('keyup', this.handleKeyup);

    if (!this.isTimesheetEditing) {
      return;
    }

    const unsavedTimeUnit = this.filteredTimeUnits.find((timeUnit) => timeUnit.id === null);

    if (unsavedTimeUnit) {
      this.cancelTimeUnitEditing(unsavedTimeUnit.date);
    }
  },

  methods: {
    ...mapActions('timesheet/timeUnits', [
      'addTimeUnit',
      'cancelTimeUnitEditing',
      'updateStoredTimeUnits',
    ]),
    ...mapActions('timesheet/filters', ['setStatusFilter']),
    ...mapActions('timesheet/table', ['changeHiddenColumns']),
    ...mapActions('shared/roles', ['getProjectRoles']),
    ...mapActions('shared/taskTypes', ['getProjectsTaskTypes']),
    getProjects: mapCacheActions('projects/main', ['getProjects']).getProjects,
    getUserProjects: mapCacheActions('auth/account', ['getUserProjects']).getUserProjects,

    /** @param {MenuItem} key */
    onXlsItemChoosen(key) {
      switch (key) {
        case MenuItem.EXCEL_EXPORT:
          this.isExcelExportDialogOpen = true;
          break;

        case MenuItem.EXCEL_IMPORT:
          this.showFileDialog();
          break;

        case MenuItem.JIRA_IMPORT:
          this.isJiraDialogOpened = true;
          break;

        case MenuItem.TEMPLATE_DOWNLOAD:
          this.downloadExcelTemplate();
          break;

        default:
          break;
      }
    },
    async fetchUserProjects() {
      await this.getUserProjects();
      const projectIds = this.userProjects.map(({ id }) => id);

      this.getProjectsTaskTypes({ projectIds });
    },
    /** @param {boolean} data */
    addRaftLogoHandler(data) {
      this.addRaftLogoValue = data;
      this.exportToExcel(true);
    },
    /** @param {boolean} is_off_hours_project */
    async exportToExcel(is_off_hours_project) {
      if (this.filteredTimeUnits.length > 0) {
        this.excelOperationInProgress = true;
        const project_id = this.projectFilter.id ? this.projectFilter.id : null;
        const status = this.statusFilter !== 'All' ? this.statusFilter : null;

        const exportParams = {
          start_date: this.dateRangeFilter.startDate,
          end_date: this.dateRangeFilter.endDate,
          employee_id: this.employeeFilter.id,
          project_id,
          status,
          is_off_hours_project,
        };

        try {
          const response = await apiClient.timeUnitApi
            .exportToExcel({ ...exportParams, include_logo: this.addRaftLogoValue });

          this.$saveFile(response);
        } finally {
          this.excelOperationInProgress = false;
        }
      } else {
        this.flashError('No units to export for selected time span');
      }
    },
    async importFromExcel() {
      this.excelOperationInProgress = true;

      const excelFile = this.$refs.importFile.files[0];
      const bodyFormData = new FormData();

      bodyFormData.append('input_file', excelFile);

      try {
        const { data } = await apiClient.timeUnitApi.importFromExcel(bodyFormData);

        this.flashSuccess(data);
        this.updateStoredTimeUnits({ reset: true });
      } finally {
        this.excelOperationInProgress = false;
        this.$refs.importFile.value = '';
      }
    },
    async downloadExcelTemplate() {
      this.excelOperationInProgress = true;

      try {
        const response = await apiClient.timeUnitApi.downloadExcel();

        this.$saveFile(response);
      } finally {
        this.excelOperationInProgress = false;
      }
    },
    showFileDialog() {
      const input = this.$refs.importFile;

      input.click();
    },
    /** @param {KeyboardEvent} event */
    handleKeyup(event) {
      if (!this.isUserTimeUnits || this.isTimesheetEditing) return;

      if ((event.key === 'Insert' && event.ctrlKey) || (event.key === 'Enter' && event.shiftKey)) {
        this.addNewTimeUnit();
      }
    },
    addNewTimeUnit() {
      const previousTimeUnit = this.filteredTimeUnits.length
        ? this.filteredTimeUnits[this.filteredTimeUnits.length - 1]
        : null;

      this.addTimeUnit(previousTimeUnit);
    },
    changeStatusColumnVisibility() {
      if (this.statusFilter !== 'All') {
        this.setStatusFilter('All');
      }

      this.changeHiddenColumns('status');
    },
    showDashboard() {
      this.$router.push('/dashboard');
    },
  },
};
</script>

<style lang="less" scoped>
@import "~@/styles/analytic-btn";
.timesheet-buttons {
  width: 480px;
  &--small {
    width: 300px;
    max-width: 300px;
  }
  &--fix-height {
    height: 40px;
  }
}
.excel-tools-button {
  .v-icon {
    transition: color 0.5s ease;
  }
  &:hover {
    .v-icon {
      color: white;
    }
  }
}
.customize-table-popover {
  margin: 2px 0 0;
  width: auto;
  transform: translateX(calc(50% - 18px));
  z-index: 9 !important;
  .v-input {
    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 4px;
    }
  }
}
</style>
