<template>
  <v-row
    :align="isMdAndDownScreen ? 'start' : 'center'"
    :class="{'flex-nowrap': isLgAndUpScreen}"
  >
    <v-combobox
      :key="employeeFilter.id"
      class="timesheet-filters-input secondary-background ma-1"
      label="Employee"
      item-text="name"
      item-value="id"
      hide-details
      auto-select-first
      outlined
      dense
      :menu-props="{ maxWidth: 240, minWidth: 240 }"
      :items="groupedEmployeeList"
      :value="employeeFilter"
      :disabled="!canFilterByEmployee || isTimesheetEditing"
      @change="onEmployeeFilterChanged"
      @focus="onFocus"
      @blur="onBlur"
    />
    <v-combobox
      :key="projectFilter.id"
      class="timesheet-filters-input secondary-background ma-1"
      label="Project"
      item-text="title"
      item-value="id"
      hide-details
      auto-select-first
      outlined
      dense
      :menu-props="{ maxWidth: 240, minWidth: 240 }"
      :items="groupedProjectsItems"
      :value="projectFilter"
      :disabled="isTimesheetEditing"
      @change="onProjectFilterChanged"
      @focus="onFocus"
      @blur="onBlur"
    />
    <v-select
      v-if="isColumnShown('status')"
      :class="{
        'timesheet-filters-input timesheet-filters-input-status secondary-background ma-1': true,
      }"
      label="Status"
      hide-details
      outlined
      dense
      :items="timeUnitStatuses"
      :value="statusFilter"
      :disabled="isTimesheetEditing"
      :menu-props="{ offsetY: true }"
      @change="(value) => changeFilter(value, 'status-filter')"
      @focus="onFocus"
      @blur="onBlur"
    />
    <div
      :class="{
        'd-flex align-center': true,
        'mt-2 timesheet-filters-bottom': isMdAndDownScreen,
      }"
    >
      <v-col
        :class="{
          'timesheet-filters-timespan': true,
          'justify-start': isMdAndDownScreen,
        }"
      >
        <TimeSpan
          id="time-filter"
          :selected="timeSpanFilter"
          :items="timeSpanValues"
          :value="timeSpanFilter"
          :disabled="isTimesheetEditing"
          @update="changeFilter"
        />
      </v-col>
      <v-col class="pr-2 timesheet-filters-data-range">
        <DateRange
          :value="dateRangeFilter"
          :display-format="dateRange"
          :disabled="isTimesheetEditing"
          :is-timesheet-page="true"
          @input="customTimeRangeChange"
          @timeRangeMove="timeRangeMove"
        />
      </v-col>
    </div>
  </v-row>
</template>

<script>
import { debounce } from 'lodash';
import { mapActions, mapGetters, mapState } from 'vuex';

import { DateRange } from '@/components/shared';
import assignmentStatuses from '@/constants/assignmentStatuses';
import { filterType } from '@/constants/filters';
import { loadingTypes } from '@/constants/loadingTypes';
import { systemRolesNames, projectRolesNames } from '@/constants/roles';
import timeSpanValues from '@/constants/timeSpan';
import timeUnitStatuses from '@/constants/timeUnitStatuses';
import { DateHelper, DateFormat } from '@/helpers/date.helper';

import TimeSpan from './TimeSpan.vue';

export default {
  components: {
    TimeSpan,
    DateRange,
  },
  props: {
    isStatusCustomizing: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    timeSpanValues,
    timeUnitStatuses,
  }),
  computed: {
    ...mapGetters('timesheet/filters', [
      'autocompleteEmployeeList',
      'employeeFilter',
      'projectFilter',
      'dateRangeFilter',
      'statusFilter',
      'timeSpanFilter',
    ]),
    ...mapGetters('timesheet/timeUnits', [
      'isTimesheetEditing',
      'isFilterSelecting',
    ]),
    ...mapGetters('timesheet/table', ['isColumnShown']),
    ...mapGetters('auth/account', [
      'user',
      'isUserEmployee',
      'userProjects',
    ]),
    ...mapState('employees/single', ['employee']),
    ...mapGetters('projects/main', [
      'projects',
      'projectAssignments',
      'employeeAssignments',
    ]),
    ...mapGetters('shared/roles', ['projectRolesMap']),

    isMdAndDownScreen() {
      return this.$vuetify.breakpoint.mdAndDown;
    },
    isLgAndUpScreen() {
      return this.$vuetify.breakpoint.lgAndUp;
    },
    groupedProjectsItems() {
      if (!(this.employeeFilter && this.employeeFilter.id)) {
        return [{ id: null, title: 'All' }, ...this.projects];
      }

      let formerProjects = [];
      let activeProjects = [];
      let otherProjects = [];
      let activeProjectsHeader = `${this.employeeFilter.name} assigned to`;
      let formerProjectsHeader = `${this.employeeFilter.name}'s former projects`;

      if (this.isEmployee && this.user.id !== this.employeeFilter.id) {
        /* It isn't possible to get detailed information by employee projects
        if user role is 'Employee', even if this user is PM or PV
        so after selecting of any employees from his managed projects in project list
        will be shown all assigned projects of selected employee without dividing to current and former projects.
        activeProjects will contain all the employee projects without dividing to active and former */
        activeProjects = this.allAvailableProjects
          .filter((project) => this.employeeFilter.assigned_projects
            .some((assignedProject) => assignedProject.project_id === project.id));

        otherProjects = this.getOtherProjects(activeProjects, formerProjects);

        return this.generateProjectList(
          activeProjectsHeader,
          formerProjectsHeader,
          activeProjects,
          formerProjects,
          otherProjects
        );
      }

      activeProjects = this.allAvailableProjects
        .filter((project) => this.employeeAssignments
          .some((projectFromAssigment) => projectFromAssigment.id === project.id
          && this.isProjectHasAllowedStatus(
            projectFromAssigment,
            [assignmentStatuses.active, assignmentStatuses.prospective]
          )));

      formerProjects = this.allAvailableProjects
        .filter((project) => this.employeeAssignments
          .some((projectFromAssigment) => !this.isActiveProject(activeProjects, projectFromAssigment.id)
          && projectFromAssigment.id === project.id
          && this.isProjectHasAllowedStatus(
            projectFromAssigment,
            [assignmentStatuses.removed, assignmentStatuses.deleted]
          )));

      otherProjects = this.getOtherProjects(activeProjects, formerProjects);

      if (this.user.id === this.employeeFilter.id) {
        activeProjectsHeader = 'Current projects';
        formerProjectsHeader = 'Former projects';
      }

      return this.generateProjectList(
        activeProjectsHeader,
        formerProjectsHeader,
        activeProjects,
        formerProjects,
        otherProjects
      );
    },

    dateRange() {
      const start = DateHelper.format(this.dateRangeFilter.startDate, DateFormat.DATE_WITH_SHORT_MONTH);
      const end = DateHelper.format(this.dateRangeFilter.endDate, DateFormat.DATE_WITH_SHORT_MONTH);

      return start === end ? `${start}` : `${start} - ${end}`;
    },
    canFilterByEmployee() {
      return this.autocompleteEmployeeList.length > 1;
    },

    groupedEmployeeList() {
      const userSection = [
        this.user,
        { divider: true },
      ];

      const formerEmployeeAssignments = [
        ...this.autocompleteEmployeeList.filter((employee) => employee.status === 'Former employee' || employee.status === 'Deleted'),
      ];

      const disabledAssignments = [
        ...this.autocompleteEmployeeList.filter((employee) => employee.status === 'Disabled'),
      ];

      const employeeList = this.autocompleteEmployeeList
        .filter((employee) => !formerEmployeeAssignments.includes(employee) && !disabledAssignments.includes(employee));

      if (this.projectFilter.id && (!this.isEmployee || this.isEmployeeManagerOnCurrentProject)) {
        const activeAssignments = this.autocompleteEmployeeList
          .filter((employee) => this.isEmployeeHasAllowedStatus(
            employee,
            [assignmentStatuses.active, assignmentStatuses.prospective]
          ));

        const formerAssignments = this.autocompleteEmployeeList
          .filter((employee) => !activeAssignments.map((active) => active.id).includes(employee.id)
          && this.isEmployeeHasAllowedStatus(
            employee,
            [assignmentStatuses.removed, assignmentStatuses.deleted]
          ));

        const otherAssignments = this.autocompleteEmployeeList
          .filter((employee) => !activeAssignments.includes(employee) && !formerAssignments.includes(employee));

        return this.generateEmployeesList(userSection, activeAssignments, formerAssignments, otherAssignments);
      }

      const isFormerEmployeeAssignmentsCategoryVisible = this.isCategoryVisible(formerEmployeeAssignments);
      const isDisabledEmployeesCategoryVisible = this.isCategoryVisible(disabledAssignments);

      return [
        ...userSection,
        ...employeeList,
        ...(isFormerEmployeeAssignmentsCategoryVisible
          ? [{ divider: true }, { header: 'Former employees:' }, ...formerEmployeeAssignments]
          : []),
        ...(isDisabledEmployeesCategoryVisible
          ? [{ divider: true }, { header: 'Disabled employees:' }, ...disabledAssignments]
          : []),
      ];
    },
    isEmployee() {
      return this.employee.employee_role === systemRolesNames.employee;
    },
    isEmployeeManagerOnCurrentProject() {
      const projectManagerRole = this.projectRolesMap[projectRolesNames.projectManager];
      const projectViewerRole = this.projectRolesMap[projectRolesNames.projectViewer];

      return this.userProjects.some((project) => project.id === this.projectFilter.id
        && project.assignments.some((assignment) => assignment.role_id === projectManagerRole.id
          || assignment.role_id === projectViewerRole.id));
    },

    allAvailableProjects() {
      return !this.projects.length ? this.userProjects : this.projects;
    },
  },
  created() {
    this.getAutocompleteEmployeeList();

    const isProjectExist = this.projectFilter !== null && this.projectFilter.id;
    const isNotOrdinaryEmployee = !this.isEmployee || this.isEmployeeManagerOnCurrentProject;

    if (isProjectExist && isNotOrdinaryEmployee) {
      this.loadProjectAssignments(this.projectFilter);
    }

    const isFiltered = this.employeeFilter !== null && this.employeeFilter.id;

    // TODO: merge into one call
    if (isFiltered) {
      this.getEmployee({ employeeId: this.user.id })
        .then(() => this.getEmployeeAssignments({ employeeId: this.user.id }))
        .then(() => this.updateStoredTimeUnits());
    } else {
      this.getEmployee({ employeeId: this.user.id })
        .then(() => this.setEmployeeFilter(this.employee))
        .then(() => this.getEmployeeAssignments({ employeeId: this.user.id }))
        .then(() => this.updateStoredTimeUnits());
    }
  },
  methods: {
    ...mapActions('timesheet/filters', [
      'getAutocompleteEmployeeList',
      'changeCustomDateRange',
      'moveDateRange',
      'setEmployeeFilter',
      'setProjectFilter',
      'setStatusFilter',
      'setTimeSpanFilter',
    ]),
    ...mapActions('employees/single', ['getEmployee']),
    ...mapActions('timesheet/timeUnits', [
      'updateStoredTimeUnits',
      'setFilterSelectingMode',
      'cancelFilterSelectingMode',
    ]),
    ...mapActions('projects/main', [
      'getProjectAssignments',
      'getEmployeeAssignments',
    ]),

    changeFilter(value, filter) {
      switch (filter) {
        case filterType.projectFilter:
          this.setProjectFilter(value);
          break;
        case filterType.timeFilter:
          this.setTimeSpanFilter(value);
          break;
        case filterType.statusFilter:
          this.setStatusFilter(value);
          break;
        default:
          if (typeof value !== 'string' && !!value) {
            this.setEmployeeFilter(value);
          }

          break;
      }
      this.updateStoredTimeUnits();
    },
    updateStoredTimeUnitsDebounced: debounce(function () {
      this.updateStoredTimeUnits();
    }, 500),
    async timeRangeMove(isForward) {
      await this.moveDateRange({
        isForward,
        dateRange: this.dateRangeFilter,
        timeSpanFilter: this.timeSpanFilter,
      });
      this.updateStoredTimeUnitsDebounced();
    },
    customTimeRangeChange(newDate) {
      this.changeCustomDateRange(newDate).then(() => this.updateStoredTimeUnits());
    },
    onFocus() {
      this.setFilterSelectingMode(true);
    },
    onBlur() {
      this.cancelFilterSelectingMode();
    },
    isCategoryVisible(listOfItems) {
      return listOfItems.length > 0;
    },
    async loadProjectAssignments(project) {
      const options = { loadingType: loadingTypes.none };

      await this.getProjectAssignments({ projectId: project.id, options });
    },
    async onProjectFilterChanged(project) {
      this.changeFilter(project, 'project-filter');

      if (project.id && (!this.isEmployee || this.isEmployeeManagerOnCurrentProject)) {
        await this.loadProjectAssignments(project);
      }
    },
    async onEmployeeFilterChanged(employee) {
      this.changeFilter(employee);

      if (employee
        && employee.id
        && (!this.isEmployee || employee.id === this.user.id)) {
        const options = { loadingType: loadingTypes.none };

        await this.getEmployeeAssignments({ employeeId: employee.id, options });
      }
    },
    generateProjectList(activeProjectsHeader, formerProjectsHeader, activeProjects, formerProjects, otherProjects) {
      const isActiveProjectCategoryDisaplayed = this.isCategoryVisible(activeProjects);
      const isFormerProjectCategoryDisplayed = this.isCategoryVisible(formerProjects);
      const isOtherProjectCategoryDispalyed = this.isCategoryVisible(otherProjects);

      return [
        { id: null, title: 'All' },
        ...(isActiveProjectCategoryDisaplayed
          ? [{ divider: true },
            { header: activeProjectsHeader },
            ...activeProjects]
          : [{ header: 'Selected user has no assigned projects' }]),
        ...(isFormerProjectCategoryDisplayed
          ? [{ divider: true }, { header: formerProjectsHeader }]
          : []),
        ...formerProjects,
        ...(isOtherProjectCategoryDispalyed
          ? [{ divider: true }, { header: 'Other projects' }]
          : []),
        ...otherProjects,
      ];
    },

    generateEmployeesList(userSection, activeAssignments, formerAssignments, otherAssignments) {
      const isActiveEmployeesCategoryDisplayed = this.isCategoryVisible(activeAssignments);
      const isOtherEmployeesCategoryDisplayed = this.isCategoryVisible(otherAssignments);
      const isFormerEmployeesCategoryDispalyed = this.isCategoryVisible(formerAssignments);
      const isCurrentUserAssignedToProject = activeAssignments.some((employee) => employee.id === this.user.id)
      || formerAssignments.some((employee) => employee.id === this.user.id);

      return [
        ...(!isCurrentUserAssignedToProject
          ? userSection
          : []),
        ...(isActiveEmployeesCategoryDisplayed
          ? [{ header: `Active assignments on ${this.projectFilter.title}:` },
            ...activeAssignments]
          : this.Employee && !this.isEmployeeManagerOnCurrentProject
            ? []
            : [{ header: 'Selected project has no active assignments' }]),
        ...(isFormerEmployeesCategoryDispalyed
          ? [{ divider: true },
            { header: `Removed assignments on ${this.projectFilter.title}:` }]
          : []),
        ...formerAssignments,
        ...(isOtherEmployeesCategoryDisplayed
          ? [{ divider: true }, { header: 'Other employees:' }]
          : []),
        ...otherAssignments,
      ];
    },

    isProjectHasAllowedStatus(project, allowedStatuses) {
      return project.assignments.some((assignment) => allowedStatuses.includes(assignment.status));
    },

    isActiveProject(activeProjects, projectId) {
      return activeProjects.some((project) => project.id === projectId);
    },

    getOtherProjects(activeProjects, formerProjects) {
      return this.allAvailableProjects.filter((project) => !activeProjects.includes(project)
        && !formerProjects.includes(project));
    },

    isEmployeeHasAllowedStatus(employee, allowedStatuses) {
      return employee.assigned_projects.some((project) => this.projectFilter.id === project.project_id)
        && this.projectAssignments.find((assignment) => assignment.employee.id === employee.id
          && assignment.project_role_title !== projectRolesNames.projectViewer
          && assignment.project_role_title !== projectRolesNames.projectManager
          && allowedStatuses.includes(assignment.status));
    },
  },
};
</script>

<style lang="less">
  .timesheet-filters-timespan {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 220px;
    flex-grow: 0;
  }
  .timesheet-filters-input {
    max-width: 240px;
    width: 30%;
  }
  .timesheet-filters-bottom {
    width: 100%;
  }
  .timesheet-filters-data-range {
    flex-grow: 0;
  }
  .timespan--narrow {
   max-width: 70px;
  }
  .timesheet-filters-input-status {
    position: relative;

    &:before {
      position: absolute;
      width: calc(100% + 16px);
      height: calc(100% + 16px);
      left: -8px;
      top: -8px;
      border-radius: 4px;
      background: #f26939;
      opacity: 0;
      content: '';
    }

    &--emphasised:before {
      opacity: 0.25;
      z-index: 1;
    }
  }
</style>
