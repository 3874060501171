<template>
  <tr
    id="currentlyEditingTimeUnit"
    v-click-outside="clickOutsideHandler"
    active
    class="row-editor"
  >
    <td />
    <td class="project-input">
      <v-select
        ref="timeUnit_project_id"
        v-model="timeUnit.project_id"
        item-text="title"
        item-value="id"
        label="Project"
        persistent-hint
        :menu-props="{ offsetY: true }"
        :items="userProjectsWithPrevOrCurrentProject"
        :autofocus="!timeUnit.project_id"
        :rules="[
          (projectId) => !!projectId || 'Required',
        ]"
        @change="onProjectChange"
      />
    </td>
    <td>
      <v-select
        ref="timeUnit_task_type_id"
        v-model="timeUnit.task_type_id"
        item-text="title"
        item-value="id"
        label="Task Type"
        persistent-hint
        :menu-props="{ offsetY: true }"
        :items="projectTaskTypes"
        :autofocus="!!timeUnit.project_id && !timeUnit.task_type_id"
        :rules="[
          (taskTypeId) => !!taskTypeId || 'Required',
        ]"
      />
    </td>
    <td class="time__cell">
      <v-combobox
        :key="timeKey"
        ref="timeUnit_time"
        v-model="timeUnit.time"
        label="Time"
        placeholder="0.00"
        :rules="timeRules"
        :error-messages="loggedTimeErrors"
        :items="timeOptions"
        @blur="roundToNearestQuarter"
        @focus="toggleTimeTooltip"
      />
      <TimeTooltip
        v-if="showTimeTooltip && focusedTimeInput"
        :available-time="availableTime"
        :used-time="usedTime"
        :message-text="calculateTextForTooltip"
      />
    </td>
    <td v-if="isColumnShown('overtime')">
      <v-checkbox
        v-model="timeUnit.overtime"
        color="primary"
        :disabled="!isOvertimeAllowed"
      />
    </td>
    <template v-if="isSystemRoleForProject">
      <td v-if="isColumnShown('is_overtime_payable')">
        <v-checkbox
          v-model="timeUnit.is_overtime_payable"
          color="primary"
          disabled
        />
      </td>
      <td v-if="isColumnShown('overtime_multiplier')">
        <v-text-field
          v-model="timeUnit.overtime_multiplier"
          color="primary"
          disabled
        />
      </td>
      <td v-if="isColumnShown('is_billable')">
        <v-checkbox
          v-model="timeUnit.is_billable"
          color="primary"
          disabled
        />
      </td>
    </template>

    <td>
      <v-textarea
        ref="timeUnit_description"
        v-model="timeUnit.description"
        label="Description"
        class="text-field"
        rows="1"
        auto-grow
        :autofocus="!!timeUnit.project_id && !!timeUnit.task_type_id"
        :rules="[
          (description) => !!description.trim() || 'Required',
        ]"
      />
    </td>
    <td>
      <v-menu
        v-model="datePickerVisible"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
      >
        <template #activator="{ on }">
          <v-text-field
            ref="timeUnit_date"
            v-model="timeUnit.date"
            label="Date"
            append-icon="event"
            :rules="[
              (date) => !!date || 'Required',
              dateIsValid,
            ]"
            v-on="on"
            @keyup.enter="datePickerVisible = true"
            @click:append="datePickerVisible = true"
          />
        </template>
        <v-date-picker
          ref="datePicker"
          color="primary"
          no-title
          :value="datePickerValue"
          :first-day-of-week="firstDayOfWeek"
          @input="saveDateInUTC"
        />
      </v-menu>
    </td>
    <td v-if="isColumnShown('status')" />
    <td class="action-column">
      <v-row
        justify="end"
        class="pt-4"
      >
        <v-btn
          text
          icon
          small
          @click="saveTimeUnit"
        >
          <v-icon>done</v-icon>
        </v-btn>
        <v-btn
          text
          icon
          small
          @click="cancelEdit"
        >
          <v-icon>clear</v-icon>
        </v-btn>
      </v-row>
    </td>
  </tr>
</template>

<script>
import debounce from 'lodash/debounce';
import moment from 'moment';
import vClickOutside from 'v-click-outside';
import { mapActions, mapGetters, mapState } from 'vuex';

import { TimeTooltip } from '@/components/shared';
import absenceTitles from '@/constants/absenceTitles';
import { defaultApproveExtraData } from '@/constants/approveExtraData';
import assignmentStatuses from '@/constants/assignmentStatuses';
import { projectRolesNames } from '@/constants/roles';
import { minutes } from '@/constants/timePeriod';
import { CollectionsHelper } from '@/helpers/collections.helper';
import { DateHelper, DateFormat, LocaleFormat } from '@/helpers/date.helper';
import { NotificationHelper } from '@/helpers/notification.helper';

export default {
  name: 'TimeUnitInput',
  components: {
    TimeTooltip,
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  props: {
    timeUnitPrototype: {
      type: Object,
      required: true,
    },
    showTimeTooltip: {
      type: Boolean,
      required: true,
    },
    dataForLeavRemainer: {
      type: Object,
      required: true,
    },
    getAbsenceHours: {
      type: Function,
      required: true,
    },

  },

  data() {
    const convertToDisplayTime = (minutes) => (minutes / 60).toFixed(2);

    const timeUnit = {
      ...this.timeUnitPrototype,
      ...defaultApproveExtraData,
      time: this.timeUnitPrototype.minutes ? convertToDisplayTime(this.timeUnitPrototype.minutes) : '',
    };

    return {
      timeUnit,
      datePickerVisible: false,
      timer: null,
      timeRules: [
        (time) => !!time || 'Required',
        (time) => !Number.isNaN(Number(time)) || 'Should be a number',
        (time) => (time > 0) || 'Should be a positive number',
        (time) => (!!timeUnit.source_name || time <= this.maxEffort)
          || `You can't submit more than ${this.maxEffort} hour${this.maxEffort > 1 ? 's' : ''}`,
      ],
      availableTime: 0,
      usedTime: 0,
      focusedTimeInput: false,
    };
  },

  computed: {
    ...mapState('timesheet/timeUnits', ['timeUnits']),
    ...mapGetters('shared/roles', [
      'projectRolesMap',
      'projectRoles',
    ]),
    ...mapGetters('auth/account', [
      'userProjects',
      'user',
    ]),
    ...mapState('employees/single', ['employee']),
    ...mapGetters('employees/single', ['isSystemRoleForProject']),
    ...mapGetters('timesheet/timeUnits', ['filteredTimeUnits']),
    ...mapGetters('shared/taskTypes', ['projectsTaskTypes']),
    ...mapGetters('timesheet/table', ['isColumnShown']),
    ...mapGetters('user/settings', ['dateFormatSettings']),

    timeKey() {
      // For some reason without computed key combobox changes twice back and forth, resulting in the same value
      return this.timeUnit.time;
    },

    selectedProject() {
      return this.userProjectsWithPrevOrCurrentProject
        .find((project) => project.id === this.timeUnit.project_id);
    },

    isOvertimeAllowed() {
      const { selectedProject } = this;

      if (!selectedProject) return false;

      /* On next line I'm checking is assignments property exists on selected project.
       * This property isn't exists only if it's not from API.
       * That means that this project is created on frontend in `userProjectsWithPrevOrCurrentProject` method.
       * `return false;` means that we falls to default OVR_allowed value — `false`;
       * TODO: Delete it when "assignments instead projects" logic will be implemented.
       */
      if (!selectedProject.assignments) return false;

      const isOVRAllowedOnAnyAssignment = selectedProject.assignments
        .some((assignment) => Boolean(Number(assignment.settings.ovr_allowed)));

      return isOVRAllowedOnAnyAssignment;
    },

    firstDayOfWeek() {
      return DateHelper.getFirstDayOfWeekAccordingSettings(this.dateFormatSettings);
    },

    maxEffort() {
      return this.employee ? (this.employee.hour_per_week || 40) : 0;
    },
    projectTaskTypes() {
      return this.projectsTaskTypes[this.timeUnit.project_id] || [];
    },

    timeUnitDateInIso() {
      const dateFormat = this.dateFormatSettings === LocaleFormat.EU ? DateFormat.EU_DATE_FORMAT : DateFormat.VISUAL;

      return DateHelper.toIso(moment(this.timeUnit.date, dateFormat));
    },
    datePickerValue() {
      if (this.timeUnitDateInIso === 'Invalid date') return DateHelper.toIso(moment());

      return this.timeUnitDateInIso;
    },
    loggedTimeErrors() {
      const errors = [];

      if (this.isTrialPeriodActive && this.isTimeExceeded) {
        errors.push(`You can't submit more than ${this.employee.hour_per_week / 5} hours a day`);
      }

      return errors;
    },
    loggedTimePerDay() {
      const selectedDayTimeUnits = (this.timeUnits.get(this.timeUnitDateInIso) || []).filter((unit) => unit.id);

      // eslint-disable-next-line no-param-reassign
      return selectedDayTimeUnits.reduce((totalTime, unit) => (totalTime += unit.minutes), 0);
    },
    isTrialPeriodActive() {
      if (this.timeUnitDateInIso === 'Invalid date') return true;

      return moment(this.employee.end_of_probation).isSameOrAfter(moment(this.timeUnitDateInIso));
    },
    isTimeExceeded() {
      let currentLoggedMinutes = this.loggedTimePerDay;

      if (this.isEdit) {
        currentLoggedMinutes -= this.timeUnitPrototype.minutes;
      }

      const currentLoggedHours = currentLoggedMinutes / 60;

      return Number(this.timeUnit.time) + currentLoggedHours > (this.employee.hour_per_week / 5);
    },
    timeUnitTaskType() {
      const foundTaskType = this.projectTaskTypes.find((taskType) => taskType.id === this.timeUnit.task_type_id);

      return foundTaskType ? this.timeUnit.task_type_id : '';
    },
    timeOptions() {
      return new Array(this.maxEffort)
        .fill(0)
        .map((_, index) => (index + 1).toFixed(2));
    },
    userProjectsWithPrevOrCurrentProject() {
      const teamMemberRole = this.projectRolesMap[projectRolesNames.teamMember];
      const activeStatusProjects = this.userProjects.filter(
        (project) => project.assignments.some(
          (assignment) => {
            const isTeamMember = assignment.role_id === teamMemberRole.id;
            const isProspective = assignment.status === assignmentStatuses.prospective;
            const isActive = assignment.status === assignmentStatuses.active;

            return isTeamMember && (isProspective || isActive);
          }
        ),
      );
      const removedStatusProjects = this.userProjects.filter(
        (project) => {
          const hasRemovedAssignment = project.assignments.some((assignment) => {
            const isTeamMember = assignment.role_id === teamMemberRole.id;
            const isRemoved = assignment.status === assignmentStatuses.removed;

            return isTeamMember && isRemoved;
          });
          const hasNoActiveAssignments = project.assignments.every((assignment) => {
            const isTeamMember = assignment.role_id === teamMemberRole.id;
            const isActive = assignment.status === assignmentStatuses.active;

            return !(isTeamMember && isActive);
          });

          return hasRemovedAssignment && hasNoActiveAssignments;
        },
      );

      const isTimeUnitExist = [
        ...activeStatusProjects,
        ...removedStatusProjects,
      ].some((project) => project.id === this.timeUnit.project_id);

      if (!isTimeUnitExist && this.isEdit) {
        activeStatusProjects.push({
          id: this.timeUnit.project_id,
          title: this.timeUnit.project_title,
        });
      }

      CollectionsHelper.sortObjectsByProperty(activeStatusProjects, 'title');
      CollectionsHelper.sortObjectsByProperty(removedStatusProjects, 'title');

      if (removedStatusProjects.length) {
        return [
          ...activeStatusProjects,
          { divider: true },
          { header: 'Former Projects' },
          ...removedStatusProjects,
        ];
      }

      return activeStatusProjects;
    },
    isEdit() {
      return !!this.timeUnitPrototype.id;
    },
    isTimeUnitValid() {
      if (this.loggedTimeErrors.length !== 0) return false;

      const isAllInputsValid = Object.entries(this.$refs)
        .every(([key, ref]) => {
          if (!key.startsWith('timeUnit_')) return true;

          return ref.validate(true);
        });

      return isAllInputsValid;
    },
    calculateTextForTooltip() {
      const { selectedProject } = this;

      return absenceTitles[selectedProject.title].messageText;
    },
  },

  watch: {
    timeUnitTaskType(taskTypeId) {
      this.timeUnit.task_type_id = taskTypeId;
    },
    datePickerVisible() {
      // TODO: investigate is it possible to get rid of setTimeouts
      if (this.datePickerVisible) {
        setTimeout(() => this.$refs.datePicker.$el.querySelector('.v-btn').focus(), 100);

        return;
      }

      setTimeout(() => { this.$refs.timeUnit_date.focus(); }, 100);
    },
    selectedProject(project) {
      if (absenceTitles[project.title]) {
        this.getAbsenceHours(absenceTitles[project.title].isVACPAbsenceCall);
      }
    },
  },

  mounted() {
    this.scrollToNewTimeUnit();
    this.updateOvertimeStatus();

    const { selectedProject } = this;

    if (selectedProject && absenceTitles[selectedProject.title] && absenceTitles[selectedProject.title].projectTitle) {
      this.getAbsenceHours(absenceTitles[selectedProject.title].isVACPAbsenceCall);
    }
  },

  created() {
    document.addEventListener('keyup', this.handleKeyup);
    document.addEventListener('keydown', this.handleKeypress);

    this.timeUnit.date = this.convertToLocaleFormat(this.timeUnitPrototype.date);
  },

  destroyed() {
    document.removeEventListener('keyup', this.handleKeyup);
    document.removeEventListener('keydown', this.handleKeypress);
  },

  methods: {
    ...mapActions('timesheet/timeUnits', [
      'createTimeUnit',
      'updateTimeUnit',
    ]),
    ...mapActions('shared/taskTypes', ['getProjectsTaskTypes']),
    ...mapActions('shared/roles', ['getProjectRoles']),

    cancelEdit() {
      this.$emit('cancel');
    },

    dateIsValid() {
      const validateFormat = this.dateFormatSettings === LocaleFormat.EU
        ? DateFormat.EU_DATE_FORMAT
        : DateFormat.VISUAL;
      const isDateValid = DateHelper.isValid(this.timeUnit.date, validateFormat);

      return !this.timeUnit.date || isDateValid || `Should be: ${validateFormat}`;
    },

    convertToLocaleFormat(date) {
      return DateHelper.formatDateAccordingSettings(date, this.dateFormatSettings);
    },

    roundToNearestQuarter() {
      this.focusedTimeInput = false;
      // To access entered time value we need to get it directly from the DOM input element
      // since v-combobox updates binded value after blur
      // Vuetify repo member: // https://github.com/vuetifyjs/vuetify/issues/3424#issuecomment-370411104
      const timeInputValue = this.$refs.timeUnit_time.$vnode.elm.querySelector('input').value;
      const time = timeInputValue || this.timeUnit.time;

      const isValid = this.$refs.timeUnit_time.validate(true);

      if (!isValid) return;

      let rounded = (Math.round(Number(time) * 4) / 4).toFixed(2);

      if (Math.round(Number(rounded) * 100) === 0) {
        rounded = 0.25;
      }

      this.timeUnit.time = rounded;
    },
    saveDateInUTC(localDate) {
      event.stopPropagation();

      this.timeUnit.date = this.convertToLocaleFormat(localDate);
      this.datePickerVisible = false;
    },

    getNextTimeUnitIdForCurrentDate() {
      if (this.timeUnitDateInIso !== this.timeUnitPrototype.date) {
        return null;
      }

      const itemIndex = this.filteredTimeUnits.findIndex((unit) => unit.id === this.timeUnitPrototype.id);

      if (itemIndex !== -1 && itemIndex + 1 < this.filteredTimeUnits.length) {
        const nextTimeUnit = this.filteredTimeUnits[itemIndex + 1];

        return (nextTimeUnit.date === this.timeUnitDateInIso) ? nextTimeUnit.id : null;
      }

      return null;
    },

    clickOutsideHandler(event) {
      const targetClasses = event.target.classList;
      // toString().includes uses because v-list-item isn't class it's substring
      const isTargetListItem = targetClasses.toString().includes('v-list-item');

      if (isTargetListItem || this.datePickerVisible) return;

      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.saveTimeUnit();
      }, 200);
    },

    saveTimeUnit() {
      const selection = window.getSelection();

      if (!this.isTimeUnitValid
        || (selection.anchorNode
          && selection.anchorNode.nodeName === '#text'
          && selection.toString())
      ) return;

      // Manually entered time or overtime values are set when blur.
      // setTimeout is needed so that the component has time to respond
      // to blur in order to send the updated time value for saving by click check mark.
      setTimeout(() => this.storeTimeUnit(), 50);
    },

    storeTimeUnit: debounce(async function () {
      const editedTimeUnit = {
        ...this.timeUnit,
        id: this.timeUnit.id,
        employee_id: this.user.id,
        description: this.timeUnit.description.trim(),
        minutes: Number(this.timeUnit.time) * 60,
        next_time_unit_id: this.getNextTimeUnitIdForCurrentDate(),
        date: this.timeUnitDateInIso,
      };

      const timeUnitNotChanged = this.isEdit && this.isTimeUnitsEquals(this.timeUnitPrototype, editedTimeUnit);

      if (timeUnitNotChanged) {
        this.cancelEdit();

        return;
      }

      const config = {
        newTimeUnit: editedTimeUnit,
        oldTimeUnit: this.timeUnitPrototype,
      };

      try {
        this.$emit('save');

        if (this.isEdit) {
          await this.updateTimeUnit(config);
        } else {
          await this.createTimeUnit(config);
        }
      } catch (error) {
        if (error.response && error.response.data.day_limit) {
          const errorMessage = NotificationHelper
            .formatLoggedTimeErrorMessage(error.response.data, this.dateFormatSettings);

          NotificationHelper.showError(errorMessage);

          return;
        }

        throw error;
      }
    }, 1000, { leading: true }),

    handleKeyup(event) {
      if (event.key === 'Enter' && event.ctrlKey) {
        this.saveTimeUnit();
      }
    },
    handleKeypress(event) {
      if (event.key === 'Escape') {
        event.preventDefault();
        this.cancelEdit();
      }
    },
    isTimeUnitsEquals(lhsTimeUnit, rhsTimeUnit) {
      const isEqual = lhsTimeUnit.project_id === rhsTimeUnit.project_id
        && lhsTimeUnit.task_type_id === rhsTimeUnit.task_type_id
        && lhsTimeUnit.minutes === rhsTimeUnit.minutes
        && lhsTimeUnit.description === rhsTimeUnit.description
        && lhsTimeUnit.date === rhsTimeUnit.date
        && lhsTimeUnit.overtime === rhsTimeUnit.overtime;

      return isEqual;
    },
    scrollToNewTimeUnit() {
      document.getElementById('currentlyEditingTimeUnit').scrollIntoView({ behavior: 'smooth', block: 'center' });
    },
    updateOvertimeStatus() {
      if (!this.isOvertimeAllowed) {
        this.timeUnit.overtime = false;
      }
    },
    updateTaskType() {
      const isTaskTypeExistsOnCurrentProject = this.projectTaskTypes
        .find((taskType) => taskType.id === this.timeUnit.task_type_id);

      if (!isTaskTypeExistsOnCurrentProject) {
        this.timeUnit.task_type_id = '';
      }
    },

    onProjectChange() {
      this.updateTaskType();
      this.updateOvertimeStatus();
    },

    toggleTimeTooltip() {
      const { selectedProject } = this;
      const selectedTaskType = this.projectTaskTypes
        .find((taskType) => taskType.id === this.timeUnit.task_type_id);

      if (
        selectedProject
        && selectedTaskType
        && absenceTitles[selectedProject.title]
        && selectedTaskType.title === absenceTitles[selectedProject.title].taskTypeTitle
      ) {
        this.focusedTimeInput = true;
        this.calculateTime();

        return;
      }

      this.focusedTimeInput = false;
    },

    calculateTime() {
      const { selectedProject } = this;

      this.usedTime = this.dataForLeavRemainer.hours_used;
      this.availableTime = this.dataForLeavRemainer.hours_left;

      if (this.timeUnitPrototype.id) {
        const timeUnitInHour = this.timeUnitPrototype.minutes / minutes;
        const differenceTime = this.timeUnit.time - this.timeUnitPrototype.minutes / minutes;

        if (timeUnitInHour !== this.timeUnit.time) {
          this.availableTime -= differenceTime;

          if (
            this.availableTime < 0
            && absenceTitles[selectedProject.title]
            && !absenceTitles[selectedProject.title].isVACPAbsenceCall
          ) {
            this.availableTime = 0;
          }
        }

        return;
      }

      if (
        absenceTitles[selectedProject.title]
        && !absenceTitles[selectedProject.title].isVACPAbsenceCall
        && this.availableTime < 0
      ) {
        this.availableTime = 0;
      }
    },
  },
};
</script>

<style lang="less">
@import "~variables";

  .row-editor {
    .project-input {
      max-width: 270px;
    }

    .v-label {
      font-size: @table-font-size;
    }

    .v-text-field__slot {
      font-size: @table-font-size;
    }

    .v-text-field {
      input {
        font-size: @table-font-size;
      }
    }

    .clipped div.v-select__selection {
      overflow: hidden;
      white-space: nowrap;
    }

    // Validation error message
    .v-input {
      .v-text-field__details {
        overflow: visible;
      }

      .v-messages__message {
        position: absolute;
        background: @soft-peach;
        border: 1px solid @pink;
        border-radius: 0 2px 2px 2px;
        width: max-content;
        max-width: 120px;
        padding: 3px;
        color: @red-dark;
        box-shadow: 0 -1px 8px 2px @pink-light;

        &::before {
          content: '';
          position: absolute;
          border: solid transparent;
          border-width: 0 6px 5px 0;
          border-bottom-color: @pink;
          top: -5px;
          left: -1px;
        }
      }
    }
    .time__cell {
      position: relative;
    }
  }
</style>
