<template>
  <v-card :height="isMdAndDownScreen ? timesheetTableCardHeightOnMdScreens : timesheetTableCardHeight">
    <v-data-table
      id="timesheet-table"
      class="align-top"
      item-key="id"
      disable-pagination
      disable-sort
      show-select
      fixed-header
      hide-default-footer
      :headers="currentTableHeaders"
      :items="filteredTimeUnits"
      :dense="denseTables"
      :value="selected"
      :class="{
        'd-flex flex-column': true,
        'timesheet-table--small': isMdAndDownScreen
      }"
      @input="setSelected"
    >
      <template #header.data-table-select="{ on , props }">
        <v-simple-checkbox
          v-bind="props"
          :disabled="isTimesheetEditing || isCurrentTimeUnitsLoading || !filteredTimeUnits.length"
          v-on="on"
        />
      </template>

      <template
        v-if="isCurrentTimeUnitsLoading"
        #body
      >
        <SkeletonTableRow
          v-for="index in 6"
          :key="index"
          have-checkbox
          checkbox-gutter
          :headers="currentTableHeaders"
        />
      </template>

      <template #item="{ isSelected, select, item }">
        <TimesheetTableRow
          :key="item.id"
          :selected="isSelected"
          :select="select"
          :item="item"
          :loading="loading"
          :employee="employee"
          :show-time-tooltip="showTimeTooltip"
          :data-for-leav-remainer="dataForLeaveRemainer"
          :get-absence-hours="getAbsenceHours"
          @onDelete="openDeleteConfirmation"
          @onSelect="selectItem"
        />
      </template>

      <template #no-data>
        No data available
      </template>

      <template #footer>
        <v-divider />
        <v-row class="table-footer flex-grow-0">
          <div class="footer-text">
            Total for the period:
            <template v-if="scheduledHours === 'NaNh'">
              loading...
            </template>
            <template v-else>
              {{ actualHours }} out of {{ scheduledHours }}
              <template v-if="overtime">; overtime: {{ overtime }}</template>
              <template v-if="(actual>scheduled)">;<span class="red--text"> potential overtime: {{ potentialOvertime }}</span></template>
            </template>
          </div>
          <v-row
            justify="end"
            class="mx-0"
          >
            <div
              class="status-buttons d-flex flex-wrap"
            >
              <SubmitBtn
                class="ma-1"
                :disabled="checkSelectedUnitsStatuses.allNotDeletable || isUserNotOwnerOfSelectedUnits"
                @clickAction="openDeleteConfirmation"
              >
                Delete
              </SubmitBtn>
              <SubmitBtn
                v-if="isColumnShown('status')"
                class="ma-1"
                :disabled="disableButtons || loading || isDraftButtonDisabled"
                @clickAction="changeStatus('Draft')"
              >
                Return to Draft
              </SubmitBtn>
              <SubmitBtn
                v-if="isColumnShown('status')"
                class="ma-1"
                :disabled="disableButtons || loading || isSubmitButtonDisabled"
                @clickAction="changeStatus('Submitted')"
              >
                Submit for Approval
              </SubmitBtn>
              <template v-if="isProjectManager && isColumnShown('status')">
                <SubmitBtn
                  class="ma-1"
                  :disabled="disableButtons || loading || isDeclineButtonDisabled"
                  @clickAction="changeStatus('Declined')"
                >
                  Decline
                </SubmitBtn>
                <SubmitBtn
                  v-if="isColumnShown('status')"
                  class="ma-1"
                  :disabled="disableButtons || loading || isApprovedButtonDisabled"
                  @clickAction="getTimeUnitsData"
                >
                  Approve
                </SubmitBtn>
              </template>
            </div>
          </v-row>
        </v-row>
      </template>
    </v-data-table>

    <DeletionConfirmationDialog
      :dialog="confirmationDialog"
      title="Confirmation"
      :text="deletionDialogText"
      confirmation-button-text="Delete"
      @confirmationAction="timeUnitDeleteConfirmation"
    />

    <ApproveTimesheetDialog
      v-if="isColumnShown('status')"
      :show-overtime="isAnySelectedOvertimed"
      :is-single-project="isSingleProjectSelected"
      :time-units-data="timeUnitsData"
      :is-opened="isApproveDialogOpened"
      @close="isApproveDialogOpened = false"
      @approve="data => changeStatus('Approved', data)"
    />
  </v-card>
</template>

<script>
import has from 'lodash/has';
import { mapActions, mapGetters, mapState } from 'vuex';

import { Button as SubmitBtn, Dialog as DeletionConfirmationDialog, SkeletonTableRow } from '@/components/shared';
import { ApproveTimesheetDialog } from '@/components/timesheet';
import { ownerStatusTransitions, PMOrDMStatusTransitions } from '@/constants/allowedStatusTransitions';
import { defaultApproveExtraData } from '@/constants/approveExtraData';
import { projectRolesNames } from '@/constants/roles';
import { timesheetTableCardHeight, timesheetTableCardHeightOnMdScreens } from '@/constants/tableHeights';
import { timeUnitStatuses } from '@/constants/timeUnitStatuses';
import { hoursToString } from '@/helpers/hours-to-string';
import { NotificationHelper } from '@/helpers/notification.helper';
import apiClient from '@/services/api-client';

import TimesheetTableRow from './TimesheetTableRow.vue';

export default {
  components: {
    DeletionConfirmationDialog,
    SkeletonTableRow,
    TimesheetTableRow,
    SubmitBtn,
    ApproveTimesheetDialog,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    isStatusCustomizing: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      timesheetTableCardHeight,
      timesheetTableCardHeightOnMdScreens,
      selected: [],
      confirmationDialog: false,
      loading: false,
      isUserNotOwnerOfSelectedUnits: true,
      isUserPMOrDMOfSelectedUnits: false,
      deleted: [],
      defaultApproveExtraData: { ...defaultApproveExtraData },
      showTimeTooltip: false,
      dataForLeaveRemainer: {},
      isSingleProjectSelected: true,
      timeUnitsData: null,
      isApproveDialogOpened: false,
    };
  },
  computed: {
    ...mapGetters('timesheet/timeUnits', [
      'isCurrentTimeUnitsLoading',
      'filteredTimeUnits',
      'persistedTimeUnits',
      'isTimesheetEditing',
    ]),
    ...mapGetters('timesheet/table', [
      'currentTableHeaders',
      'isColumnShown',
    ]),
    ...mapGetters('user/settings', ['denseTables']),
    ...mapGetters('auth/account', [
      'userProjects',
      'user',
    ]),
    ...mapState('employees/single', [
      'employee',
      'schedule',
    ]),
    ...mapGetters('employees/single', ['isProjectManager']),
    ...mapGetters('timesheet/filters', ['employeeFilter']),
    ...mapGetters('shared/roles', ['projectRolesMap']),
    ...mapGetters('projects/main', [
      'projectAssignments',
      'employeeAssignments',
    ]),

    deletionDialogText() {
      const deletedNumberText = this.deleted.length > 1 ? this.deleted.length : 'the';

      return `Are you sure you want to delete ${deletedNumberText} time unit${this.deleted.length > 1 ? 's' : ''}?`;
    },

    checkSelectedUnitsStatuses() {
      let numberOfDraftStatuses = 0;
      let numberOfSubmittedStatuses = 0;
      let numberOfDeclinedStatuses = 0;
      let numberOfApprovedStatuses = 0;
      let numberOfNotDeletableUnits = 0;

      // TODO: rework using reduce
      this.selected.forEach((el) => {
        switch (el.status) {
          case timeUnitStatuses.draft:
            numberOfDraftStatuses += 1;
            break;

          case timeUnitStatuses.declined:
            numberOfDeclinedStatuses += 1;
            break;

          case timeUnitStatuses.approved:
            numberOfApprovedStatuses += 1;
            numberOfNotDeletableUnits += 1;
            break;

          case timeUnitStatuses.submitted:
            numberOfSubmittedStatuses += 1;
            numberOfNotDeletableUnits += 1;
            break;

          default:
            break;
        }
      });

      return {
        allDraft: numberOfDraftStatuses === this.selected.length,
        allSubmitted: numberOfSubmittedStatuses === this.selected.length,
        allDeclined: numberOfDeclinedStatuses === this.selected.length,
        allApproved: numberOfApprovedStatuses === this.selected.length,
        allNotDeletable: numberOfNotDeletableUnits === this.selected.length,
      };
    },

    isDraftButtonDisabled() {
      const { allDraft, allDeclined, allApproved } = this.checkSelectedUnitsStatuses;

      return allDraft || allDeclined || allApproved || this.isUserNotOwnerOfSelectedUnits;
    },
    isSubmitButtonDisabled() {
      const { allSubmitted, allApproved } = this.checkSelectedUnitsStatuses;

      return allSubmitted || allApproved || this.isUserNotOwnerOfSelectedUnits;
    },
    isDeclineButtonDisabled() {
      const { allDeclined } = this.checkSelectedUnitsStatuses;

      return allDeclined || !this.isUserPMOrDMOfSelectedUnits;
    },
    isApprovedButtonDisabled() {
      const { allApproved } = this.checkSelectedUnitsStatuses;

      return allApproved || !this.isUserPMOrDMOfSelectedUnits;
    },

    disableButtons() {
      return !this.selected.length;
    },
    actual() {
      return this.getTotalHours(false);
    },
    actualHours() {
      return hoursToString(this.actual);
    },
    overtime() {
      const actualHours = this.getTotalHours(true);

      return actualHours === 0 ? null : hoursToString(actualHours);
    },
    scheduled() {
      let total = 0;
      const employee = this.getFilteredEmployee();

      if (this.schedule) {
        const employeeSchedule = this.schedule.find((item) => item.employee_id === employee.id);

        if (employeeSchedule) total = employeeSchedule.scheduled_hours;
      }

      return total;
    },
    scheduledHours() {
      return hoursToString(this.scheduled);
    },
    potentialOvertime() {
      return hoursToString(this.actual - this.scheduled);
    },
    allItemsSelected() {
      return this.selected.length > 0 && this.selected.length === this.filteredTimeUnits.length;
    },
    someItemsSelected() {
      return this.selected.length > 0 && !this.allItemsSelected;
    },
    isAnySelectedOvertimed() {
      return Boolean(this.selected.some((s) => s.overtime));
    },
    isMdAndDownScreen() {
      return this.$vuetify.breakpoint.mdAndDown;
    },
  },
  watch: {
    isTimesheetEditing() {
      if (this.isTimesheetEditing) {
        this.selected = [];
      }
    },
    filteredTimeUnits() {
      // When user selected TU but filtered it with request to the server
      // need to deselect such units
      const timeUnitIds = this.filteredTimeUnits.map((item) => item.id);

      this.selected = this.selected.filter((timeUnit) => timeUnitIds.includes(timeUnit.id));
    },
    selected() {
      const PMAndDMProjects = this.filterUserProjectsByRole(
        projectRolesNames.projectManager,
        projectRolesNames.deliveryManager
      );

      this.isUserPMOrDMOfSelectedUnits = this.selected.every((timeUnit) => (
        PMAndDMProjects.find((project) => project.id === timeUnit.project_id)
      ));
      this.isUserNotOwnerOfSelectedUnits = !this.selected.map((unit) => unit.employee_id).includes(this.user.id);
    },
  },
  mounted() {
    this.isShowTimeTooltip();
  },
  methods: {
    ...mapActions('timesheet/timeUnits', [
      'deleteTimeUnit',
      'updateTimeUnitsStatuses',
      'deleteBatchTimeUnits',
      'getLeavAbsenceHours',
      'getVacpAbsenceHours',
      'updateStoredTimeUnits',
    ]),
    ...mapActions('timesheet/table', ['changeHiddenColumns']),

    ...mapActions('projects/main', [
      'setEmployeeProjectRole',
      'removeEmployeeFromProject',
    ]),
    ...mapActions('projects/main', ['setIsShowOnlyMyOffice']),
    ...mapActions('shared/roles', ['getProjectRoles']),

    filterUserProjectsByRole(...roles) {
      return roles
        .map((role) => {
          const neededRole = this.projectRolesMap[role];

          return this.userProjects
            .filter((up) => up.assignments.some((assignment) => assignment.role_id === neededRole.id));
        }).flat();
    },
    getFilteredEmployee() {
      const { id, hour_per_week } = this.employeeFilter;

      return id && hour_per_week ? this.employeeFilter : this.employee;
    },
    openDeleteConfirmation(item) {
      const filteredSelection = this.selected.filter((el) => {
        const isNotApproved = el.status !== timeUnitStatuses.approved;
        const isNotSubmitted = el.status !== timeUnitStatuses.submitted;

        return isNotApproved && isNotSubmitted;
      });

      this.deleted = item
        ? [item]
        : filteredSelection;

      this.confirmationDialog = true;
    },
    timeUnitDeleteConfirmation(confirmed) {
      if (confirmed) {
        this.deleteBatchTimeUnits(this.deleted);
      }

      if (this.deleted.length) {
        this.deleted = [];
      }

      this.confirmationDialog = false;
    },
    setSelected(selected) {
      this.selected = selected;
    },
    async getTimeUnitsData() {
      const selectedProjectIds = [...new Set(this.selected.map((timeUnit) => timeUnit.project_id))];

      if (selectedProjectIds.length > 1) {
        this.isSingleProjectSelected = false;
        this.timeUnitsData = null;
      } else {
        this.isSingleProjectSelected = true;

        const hasEmptyAssignmentId = this.selected.some((unit) => unit.assignment_id === null);

        if (hasEmptyAssignmentId) {
          // save selected units to select them again after reset
          const toSelect = this.selected.map((timeUnit) => timeUnit.id);

          await this.updateStoredTimeUnits({ reset: true });
          // stored and get updated time-unit list
          const updatedFilteredUnits = await this.filteredTimeUnits;

          const updatedUnitsToSelect = updatedFilteredUnits.filter((unit) => toSelect.includes(unit.id));

          this.setSelected(updatedUnitsToSelect);
        }

        this.timeUnitsData = {
          timeUnitsAssignmentIds: [...new Set(this.selected.map((unit) => unit.assignment_id))],
          timeUnitsEmployeeId: this.selected[0].employee_id,
          timeUnitsProjectId: selectedProjectIds[0],
        };
      }

      this.isApproveDialogOpened = true;
    },
    changeStatus(status, extraData = this.defaultApproveExtraData) {
      const PMAndDMProjects = this.filterUserProjectsByRole(
        projectRolesNames.projectManager,
        projectRolesNames.deliveryManager
      );

      /* eslint-disable no-param-reassign */
      const PMOrDMProjectsMap = PMAndDMProjects.reduce((PMOrDMProjectsMap, project) => {
        PMOrDMProjectsMap[project.id] = project;

        return PMOrDMProjectsMap;
      }, {});
      /* eslint-enable */

      const failedItems = [];

      const timeUnitsToUpdate = this.selected.filter((unit) => {
        const isUserOwnerOfTimeUnits = this.user.id === unit.employee_id;
        const isUserPMOrDM = has(PMOrDMProjectsMap, unit.project_id);

        const isTransitionAllowedForOwner = !!ownerStatusTransitions
          .find((transition) => transition.current === unit.status && transition.updated === status);
        const isTransitionAllowedForPMOrDM = !!PMOrDMStatusTransitions
          .find((transition) => transition.current === unit.status && transition.updated === status);

        const isTransitionAllowed = (isUserOwnerOfTimeUnits && isTransitionAllowedForOwner)
         || (isUserPMOrDM && isTransitionAllowedForPMOrDM);

        if (!isTransitionAllowed) {
          failedItems.push(unit);
        }

        return isTransitionAllowed;
      });

      this.updateTimeUnitsStatuses({ status, timeUnitsToUpdate, extraData });

      if (timeUnitsToUpdate.length) {
        this.flashSuccess(`Successfully updated status of ${timeUnitsToUpdate.length} time units`);
      }

      if (failedItems.length) {
        this.flashError(`Failed to update status of ${failedItems.length} time units`);
      }

      this.selected = failedItems;
    },

    selectItem(item) {
      const foundIndex = this.selected.findIndex((v) => v.id === item.id);

      if (foundIndex !== -1) {
        this.selected.splice(foundIndex, 1);
      } else {
        this.selected.push(item);
      }
    },
    getTotalHours(isOvertime) {
      const actualHours = this.persistedTimeUnits
        .filter((r) => r.overtime === isOvertime)
        .reduce((acc, timeUnit) => acc + timeUnit.minutes, 0) / 60;

      return actualHours;
    },
    async isShowTimeTooltip() {
      try {
        const { data } = await apiClient.permissionsApi.getAbsenceCalc();

        this.showTimeTooltip = data.allowed;
      } catch (error) {
        if (error.response && error.response.data.day_limit) {
          const errorMessage = NotificationHelper
            .formatLoggedTimeErrorMessage(error.response.data, this.dateFormatSettings);

          NotificationHelper.showError(errorMessage);

          return;
        }

        throw error;
      }
    },
    async getAbsenceHours(isNeedCall) {
      let result;

      try {
        result = isNeedCall ? await this.getVacpAbsenceHours() : await this.getLeavAbsenceHours();
      } catch (error) {
        // handle error
      } finally {
        this.dataForLeaveRemainer = result;
      }
    },
  },
};
</script>

<style lang="less">
  @import "~variables";
  .timesheet-table__header {
    &-title {
      min-width: 270px;
      width: 270px;
    }
    &-type {
      min-width: 165px;
      width: 165px;
    }
    &-time {
      min-width: 125px;
      width: 125px;
    }
    &-overtime {
      min-width: 110px;
      width: 110px;
    }
    &-overtime-payable {
      min-width: 155px;
      width: 155px;
    }
    &-overtime-multiplier {
      min-width: 155px;
      width: 155px;
    }
    &-billable {
      min-width: 50px;
      width: 50px;
    }
    &-description {
      min-width: 240px;
    }
    &-date {
      min-width: 135px;
      width: 135px;
    }
    &-status {
      min-width: 100px;
      width: 100px;
    }
    &-buttons {
      min-width: 85px;
      width: 90px;
    }
  }
  .v-data-table.timesheet-table--small {
    td {
      padding: 0 5px;
    }
    th:not(:first-child) {
      padding: 0 5px;
    }
    th:first-child {
      padding-right: 5px;
    }
    .timesheet-table__header {
      &-title {
        min-width: 200px;
        width: 200px;
      }
      &-type {
        min-width: 140px;
        width: 140px;
      }
      &-time {
        min-width: 50px;
        width: 50px;
      }
      &-description {
        min-width: 195px;
      }
      &-date {
        min-width: 115px;
        width: 115px;
      }
      &-status {
        min-width: 80px;
        width: 80px;
      }
      &-buttons {
        min-width: 65px;
        width: 65px;
      }
    }
  }
  #timesheet-table {
    overflow: auto;
    height: 100%;

    .v-data-table__progress {
      display: none;
    }
    .v-data-table__wrapper {
      flex-grow: 1;
    }

    .v-data-table__empty-wrapper {
      td {
        padding: 0;
      }
      &:hover {
        background: white;
      }
    }

    .v-simple-checkbox {
      margin-left: 8px;
    }

    th.add-button-column {
      background-color: white;
      position: sticky;
      z-index: 2;
    }

    .checkbox-column {
      padding-left: 0;
    }

    tbody::after {
      content: '';
      display: block;
      height: 24px;
    }

    .status-buttons {
      position: relative;

      &:before {
        position: absolute;
        width: calc(100% + 16px);
        height: calc(100% + 16px);
        left: -8px;
        top: -8px;
        border-radius: 4px;
        background: #f26939;
        opacity: 0;
        content: '';
      }

      &--emphasised:before {
        opacity: 0.25;
      }
    }
  }
</style>
