<template>
  <v-tooltip right>
    <template #activator="{ on }">
      <span
        class="TimeUnitSourceIcon"
        v-on="on"
      >
        <v-icon
          right
          size="14"
          :class="icon.class"
        >
          {{ icon.name }}
        </v-icon>
      </span>
    </template>
    <span>
      {{ `Imported from ${sourceName}` }}
    </span>
  </v-tooltip>
</template>

<script>
import { ImportSource } from '@/constants/importSource';

export default {
  props: {
    sourceName: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      ImportSource,
    };
  },

  computed: {
    icon() {
      if (this.sourceName === 'JIRA') {
        return {
          name: 'mdi-jira',
          class: 'blue-hover',
        };
      }

      return {
        name: 'mdi-file-excel',
        class: 'green-hover',
      };
    },
  },
};
</script>

<style lang="less">
@import "~variables";
.TimeUnitSourceIcon {
  .blue-hover {
    &:hover {
      color: @blue;
    }
  }

  .green-hover {
    &:hover {
      color: @green;
    }
  }
}
</style>
