<template>
  <v-dialog
    :value="isOpened"
    max-width="400px"
  >
    <v-card>
      <v-card-title>
        <span class="text-h5">Approve as</span>
      </v-card-title>
      <v-card-text>
        <template>
          <v-row class="pt-4">
            <v-col>
              <v-select
                v-model="approveData.assignment_id"
                class="secondary-background"
                :menu-props="{ offsetY: true }"
                item-value="id"
                :item-text="getAssignmentText"
                :items="assignments"
                label="Assignment"
                :disabled="!isSingleProject"
                hide-details
                outlined
                dense
              />
            </v-col>
          </v-row>
          <v-item-group
            v-if="!isSingleProject"
            class="pt-1"
          >
            <v-item>
              <span>To specify the assignment, please select time units that belong to only one project.</span>
            </v-item>
          </v-item-group>
        </template>
        <template v-if="showOvertime">
          <v-row class="pt-4">
            <v-col>
              <v-select
                v-model="approveData.overtime_multiplier"
                :menu-props="{ offsetY: true }"
                class="secondary-background"
                :items="overtimeMultiplierItems"
                label="Overtime multiplier"
                :disabled="!approveData.is_overtime_payable"
                hide-details
                outlined
                dense
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-switch
                v-model="approveData.is_overtime_payable"
                label="Payable overtime"
                color="primary"
                hide-details
              />
            </v-col>
          </v-row>
        </template>
        <v-row>
          <v-col>
            <v-switch
              v-model="approveData.is_billable"
              label="Billable"
              color="primary"
              hide-details
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <div class="flex-grow-1" />
        <v-btn
          text
          color="primary"
          @click="onApprove"
        >
          Approve
        </v-btn>
        <v-btn
          text
          @click="closeDialog"
        >
          cancel
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import { defaultApproveExtraData, overtimeMultiplierItems } from '@/constants/approveExtraData';

export default {
  props: {
    showOvertime: {
      type: Boolean,
      default: true,
    },
    isSingleProject: {
      type: Boolean,
      default: true,
    },
    timeUnitsData: {
      type: Object,
      default: null,
    },
    isOpened: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      approveData: { ...defaultApproveExtraData },
      overtimeMultiplierItems,
      assignments: [],
    };
  },
  computed: {
    ...mapGetters('projects/main', [
      'projectAssignments',
    ]),
    overtime() {
      return this.approveData.is_overtime_payable;
    },
  },
  watch: {
    overtime() {
      this.approveData.overtime_multiplier = this.approveData.is_overtime_payable ? 1 : 0;
    },
    isOpened(opened) {
      if (opened) {
        this.getAssignments();
      }
    },
  },
  methods: {
    ...mapActions('projects/main', [
      'getProjectAssignments',
      'clearProjectAssignments',
    ]),

    isCategoryVisible(listOfItems) {
      return listOfItems.length > 0;
    },

    async getAssignments() {
      if (this.timeUnitsData === null) {
        this.assignments = [];
      } else {
        const { timeUnitsProjectId } = this.timeUnitsData;
        const { timeUnitsEmployeeId } = this.timeUnitsData;

        await this.getProjectAssignments({ projectId: timeUnitsProjectId });

        const allAssignments = this.projectAssignments.filter(
          (assignment) => assignment.employee.id === timeUnitsEmployeeId
            && (assignment.sow_po_number !== null || assignment.billable_status !== null)
        );

        const activeAssignments = [
          ...allAssignments.filter((assignment) => assignment.status !== 'Removed' && assignment.status !== 'Deleted'),
        ];

        const removedAssignments = [
          ...allAssignments.filter((assignment) => assignment.status === 'Removed' || assignment.status === 'Deleted'),
        ];

        const isActiveAssignmentsCategoryVisible = this.isCategoryVisible(activeAssignments);
        const isRemovedAssignmentsCategoryVisible = this.isCategoryVisible(removedAssignments);

        this.assignments = [
          ...(isActiveAssignmentsCategoryVisible
            ? [...activeAssignments]
            : []),
          ...(isRemovedAssignmentsCategoryVisible
            ? [{ divider: true }, { header: 'Removed:' }, ...removedAssignments]
            : []),
        ];

        this.getDefaultAssignment();
      }
    },

    getDefaultAssignment() {
      const { timeUnitsAssignmentIds } = this.timeUnitsData;

      if (timeUnitsAssignmentIds.length === 1) {
        const { 0: current_assignment_id } = timeUnitsAssignmentIds;

        this.approveData.assignment_id = current_assignment_id;
      } else if (timeUnitsAssignmentIds.length >= 2) {
        this.assignments.unshift({ id: null });
        this.approveData.assignment_id = null;
      }
    },

    getAssignmentText(item) {
      // no need to get text for such items
      if (item.divider || item.header) {
        return item;
      }

      let assignment_contract = '';

      if (item.id === null) {
        assignment_contract = 'Selected time units have several assignments';
      } else if (item.sow_po_number && item.billable_status === 'Billable') {
        assignment_contract = `${item.sow_po_number}, ${item.sow_type}, ${item.position}`;
      } else {
        assignment_contract = `${item.billable_status}, ${item.position}`;
      }

      return assignment_contract;
    },

    closeDialog() {
      this.$emit('close');
      this.approveData = { ...defaultApproveExtraData };
      this.assignments = [];
      this.clearProjectAssignments();
    },
    onApprove() {
      this.$emit('approve', this.approveData);
      this.closeDialog();
    },
  },
};
</script>
