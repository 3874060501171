<template>
  <v-chip-group
    column
    :value="value"
  >
    <v-chip
      v-for="item in items"
      :key="item.id"
      class="time-span-chip"
      label
      :color="getButtonColor(item.id, value)"
      :value="item.id"
      :disabled="disabled"
      @click="onClick(item.id)"
      @keyup.enter="onClick(item.id)"
    >
      {{ item.title }}
    </v-chip>
  </v-chip-group>
</template>

<script>
import { mapGetters } from 'vuex';

import { timePeriod } from '@/constants/timePeriod';
import { LocaleFormat } from '@/helpers/date.helper';

export default {
  props: {
    items: {
      type: Array,
      required: true,
    },
    id: {
      type: String,
      default: null,
    },
    value: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('user/settings', ['dateFormatSettings']),
  },
  methods: {
    onClick(value) {
      const period = value === timePeriod.week && this.dateFormatSettings === LocaleFormat.EU
        ? timePeriod.isoWeek
        : value;

      this.$emit('update', period, this.id);
    },
    getButtonColor(buttonID, value) {
      const selectedValue = value === 'isoWeek' ? 'week' : value;
      const buttonActiveColor = 'primary v-chip--active';
      const buttonInactiveColor = '#f5f5f5;';
      const buttonColor = selectedValue === buttonID ? buttonActiveColor : buttonInactiveColor;

      return buttonColor;
    },
  },
};
</script>

<style lang="less">
  .time-span-chip {
    min-width: 64px;

    &:last-child {
      margin-right: 0;
    }

    span {
      margin:auto;
    }
  }

  .v-select__selection {
    font-size: 14px;
  }

  .selector {
    margin-bottom: 10px;
  }
</style>
