<template>
  <tr
    v-if="!isEditing && item.id"
    :key="item.id"
    active-class="highlighted"
    :active="selected"
    :class="item.isEven ? '' : 'even-day-row-highlight'"
    @click="editTimeUnit"
  >
    <td
      class="checkbox-column"
      @click.stop
    >
      <div class="add-button-wrapper">
        <v-btn
          v-if="isUserTimeUnits"
          color="primary"
          class="add-button"
          absolute
          icon
          small
          :disabled="isTimesheetEditing"
          @click.prevent="addItem"
        >
          <v-icon>add</v-icon>
        </v-btn>
        <v-checkbox
          class="select-item-checkbox"
          color="primary"
          hide-details
          :input-value="selected"
          :disabled="isTimesheetEditing"
          @change="selectItem"
        />
      </div>
    </td>
    <td>
      <span class="cell-content-with-ellipsis">
        <span>{{ item.project_title }}</span>
      </span>
    </td>
    <td>
      <span>{{ item.task_type_title }}</span>
      <TimeUnitIcon
        v-if="!!item.source_name"
        :source-name="item.source_name"
      />
    </td>
    <v-tooltip left>
      <template #activator="{ on }">
        <td
          :class="getLoggedHoursHighlight()"
          v-on="on"
        >
          {{ item.minutes | convertToDisplayTime }}
        </td>
      </template>
      <span>{{ tooltipHours() }}</span>
    </v-tooltip>
    <td v-if="isColumnShown('overtime')">{{ item.overtime | readableBoolean }}</td>
    <template v-if="isSystemRoleForProject">
      <td v-if="isColumnShown('is_overtime_payable')">
        {{ item.is_overtime_payable | readableBoolean }}
      </td>
      <td v-if="isColumnShown('overtime_multiplier')">
        {{ item.overtime_multiplier === null ? '0' : item.overtime_multiplier }}
      </td>
      <td v-if="isColumnShown('is_billable')">
        {{ item.is_billable | readableBoolean }}
      </td>
    </template>

    <td>{{ item.description }}</td>
    <td>{{ item.date | convertToDisplayDate(dateFormatSettings) }}</td>
    <td v-if="isColumnShown('status')">{{ item.status }}</td>
    <td class="action-column">
      <v-row justify="end">
        <v-btn
          v-if="isUserTimeUnits"
          text
          icon
          small
          :disabled="loading || isTimesheetEditing"
          @click.prevent="duplicateItem"
        >
          <v-icon class="material-icons-outlined">file_copy</v-icon>
        </v-btn>
        <v-btn
          v-if="isUserTimeUnits"
          text
          icon
          small
          :disabled="item.is_time_unit_locked || loading || isTimesheetEditing"
          @click.prevent="deleteRow"
        >
          <v-icon>delete_outline</v-icon>
        </v-btn>
      </v-row>
    </td>
  </tr>

  <TimeUnitInput
    v-else
    :key="item.id"
    :time-unit-prototype="item"
    :show-time-tooltip="showTimeTooltip"
    :data-for-leav-remainer="dataForLeavRemainer"
    :get-absence-hours="getAbsenceHours"
    @save="setEditing(false)"
    @cancel="cancelEditingTimeUnit"
  />
</template>

<script>
import Vue from 'vue';
import { mapActions, mapGetters, mapState } from 'vuex';

import { timeUnitStatuses } from '@/constants/timeUnitStatuses';
import { DateHelper } from '@/helpers/date.helper';

import TimeUnitIcon from './TimeUnitIcon.vue';
import TimeUnitInput from './TimeUnitInput.vue';

export default Vue.extend({
  components: {
    TimeUnitInput,
    TimeUnitIcon,
  },

  filters: {
    readableBoolean(text) {
      return text ? 'YES' : 'NO';
    },
    convertToDisplayDate(date, dateFormat) {
      const isoDate = DateHelper.toIso(date);

      return DateHelper.formatDateWithDayOfWeekAccordingSettings(isoDate, dateFormat);
    },
    convertToDisplayTime(minutes) {
      return `${(minutes / 60).toFixed(2)}h`;
    },
  },

  props: {
    selected: {
      type: Boolean,
      required: true,
    },
    select: {
      type: Function,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    showTimeTooltip: {
      type: Boolean,
      required: true,
    },
    dataForLeavRemainer: {
      type: Object,
      required: true,
    },
    getAbsenceHours: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      isEditing: false,
    };
  },

  computed: {
    ...mapGetters('timesheet/timeUnits', [
      'filteredTimeUnits',
      'isTimesheetEditing',
      'isFilterSelecting',
    ]),
    ...mapGetters('timesheet/table', ['isColumnShown']),
    ...mapGetters('timesheet/filters', ['employeeFilter']),
    ...mapState('employees/single', ['employee']),
    ...mapGetters('employees/single', ['isSystemRoleForProject']),
    ...mapGetters('user/settings', ['dateFormatSettings']),

    isUserTimeUnits() {
      return this.employeeFilter.id === null || this.employee.id === this.employeeFilter.id;
    },

    cannotEditTimeUnit() {
      return !this.isUserTimeUnits
        || this.item.is_time_unit_locked
        || this.isEditing
        || this.isTimesheetEditing;
    },

    getLastEditableTimeUnit() {
      const editableTimeUnits = this.filteredTimeUnits.filter((timeUnit) => timeUnit.status === timeUnitStatuses.draft
        || timeUnit.status === timeUnitStatuses.declined);

      return editableTimeUnits[editableTimeUnits.length - 1];
    },
  },

  watch: {
    filteredTimeUnits() {
      const lastEditableTimeUnit = this.getLastEditableTimeUnit;

      if (!lastEditableTimeUnit || this.item.id !== lastEditableTimeUnit.id) {
        document.removeEventListener('keyup', this.arrowUpKeyHandler);
      } else {
        document.addEventListener('keyup', this.arrowUpKeyHandler);
      }
    },
  },

  mounted() {
    const lastEditableTimeUnit = this.getLastEditableTimeUnit;

    if (lastEditableTimeUnit && this.item.id === lastEditableTimeUnit.id) {
      document.addEventListener('keyup', this.arrowUpKeyHandler);
    }
  },

  beforeDestroy() {
    document.removeEventListener('keyup', this.arrowUpKeyHandler);
  },

  methods: {
    ...mapActions('timesheet/timeUnits', [
      'addTimeUnit',
      'duplicateTimeUnit',
      'cancelTimeUnitEditing',
      'setTimesheetEditMode',
    ]),

    arrowUpKeyHandler(event) {
      if (
        !this.isFilterSelecting
        && this.isUserTimeUnits
        && !this.isTimesheetEditing
        && (event.key === 'ArrowUp' || (event.ctrlKey && event.key === 'ArrowUp'))
      ) {
        this.setEditing(true);
      }
    },
    deleteRow() {
      this.$emit('onDelete', this.item);
    },
    selectItem() {
      this.$emit('onSelect', this.item);
    },
    addItem() {
      this.addTimeUnit(this.item);
    },
    duplicateItem() {
      this.duplicateTimeUnit(this.item);
    },
    cancelEditingTimeUnit() {
      this.cancelTimeUnitEditing(this.item.date);
      this.setEditing(false);
    },
    timeUnitImportedFrom() {
      return this.item.source_name;
    },
    editTimeUnit(event) {
      if (this.cannotEditTimeUnit || event.defaultPrevented || window.getSelection().toString()) return;

      this.setEditing(true);
    },
    getLoggedHoursHighlight() {
      const currentlySelectedEmployee = this.isUserTimeUnits ? this.employee : this.employeeFilter;
      const expectedTimePerDay = (currentlySelectedEmployee.hour_per_week / 5) * 60;
      const loggedTimePerDay = this.getLoggedTimePerDay();

      switch (true) {
        case (loggedTimePerDay < expectedTimePerDay):
          return 'red lighten-4';
        case (loggedTimePerDay > expectedTimePerDay):
          return 'amber lighten-4';
        default: // equal
          return 'green lighten-3';
      }
    },
    /** @returns {string} */
    tooltipHours() {
      const currentlySelectedEmployee = this.isUserTimeUnits ? this.employee : this.employeeFilter;
      const loggedHoursPerDay = this.getLoggedTimePerDay() / 60;
      const formattedLoggedTimePerDay = Math.floor(loggedHoursPerDay * 100) / 100;

      return `${formattedLoggedTimePerDay}/${currentlySelectedEmployee.hour_per_week / 5}`;
    },
    getLoggedTimePerDay() {
      return this.filteredTimeUnits
        .filter((unit) => unit.id && unit.date === this.item.date)
        // eslint-disable-next-line no-param-reassign
        .reduce((aggregation, unit) => (aggregation += unit.minutes), 0);
    },
    setEditing(value) {
      this.isEditing = value;
      this.setTimesheetEditMode(value);
    },
  },
});
</script>

<style lang="less">
@import "~variables";
#timesheet-table {
  .add-button-wrapper {
    position: relative;
    width: 24px;
    height: 100%;

    .v-input {
      padding-left: 24px;
    }

    .add-button {
      min-width: 24px;
      width: 24px;
      height: 24px;
      bottom: -16px;
      z-index: 1;
      overflow: hidden;
      transition: background-color 0.5s ease;
      i {
        transition: transform 0.5s ease, color 0.5s ease;
      }
      &:hover {
        background-color: #f26a399f;
        i {
          color: white;
          transform: rotate(360deg);
        }
      }
    }
  }

  tr:not(:hover) .add-button {
    display: none;
  }

  .v-data-table__checkbox {
    padding-left: 8px;
  }

  .action-column {
    .row {
      margin: 0;

      .v-btn {
        color: #555;
        margin: 1px;
        height: inherit;
        width: inherit;
        min-width: unset;

        &:hover {
          background-color: rgba(0,0,0,.1);
          transition: all 0.5s ease;
        }
      }
    }
  }

  .even-day-row-highlight {
    background-color: @grey; // blue-grey lighten-4

    &:hover {
      background-color: @grey-light; // blue-grey lighten-5
    }
  }

  tr[active]:not(.row-editor) {
    background: @orange-12pct;
    &:hover {
      background-color: @grey-light;
    }
  }

  .cell-content-with-ellipsis {
    display: inline-block;
    width: 100%;
    position: relative;

    &:before {
      content: '&nbsp;';
      visibility: hidden;
    }

    span {
      position: absolute;
      left: 0;
      right: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  // TODO: remove this unused class
  .cell-content {
    display: inline-block;
    width: 100%;
    position: relative;
    white-space: nowrap;
    overflow: hidden;
  }

  // Description
  tr td:nth-child(7) { word-break: break-all; }
}
</style>
